import Layout from "@/layout";
var blindboxRouter = {
  path: '/blindbox',
  component: Layout,
  redirect: '/blindbox/boxList',
  name: 'blindbox',
  meta: {
    title: '盲盒',
    icon: 'takeaway-box'
  },
  children: [{
    path: 'boxList',
    name: 'boxList',
    component: function component() {
      return import('@/views/blindbox/box/list');
    },
    meta: {
      title: '盲盒管理',
      icon: ''
    }
  }, {
    path: 'boxgoods',
    name: 'boxgoods',
    component: function component() {
      return import('@/views/blindbox/boxgoods/list');
    },
    meta: {
      title: '商品管理',
      icon: ''
    }
  }, {
    path: 'record',
    name: 'record',
    component: function component() {
      return import('@/views/blindbox/record/list');
    },
    meta: {
      title: '中奖记录',
      icon: ''
    }
  }, {
    path: 'order',
    name: 'order',
    component: function component() {
      return import('@/views/blindbox/order/list');
    },
    meta: {
      title: '订单',
      icon: ''
    }
  }]
};
export default blindboxRouter;